//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-736:_5564,_8525,_1816,_6940,_5436,_3164,_3968,_4652;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-736')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-736', "_5564,_8525,_1816,_6940,_5436,_3164,_3968,_4652");
        }
      }catch (ex) {
        console.error(ex);
      }